@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.partnership-agreement-block {
  @include flexBetween;

  & > .column {
    @include flexColumn;

    &:not(:last-child) { margin-right: 20px; }
    &:last-child {
      align-items: flex-start;
      font-weight: bold;
      color: $green4;
    }
  }
}
