@import "../../../styles/mixins";
@import "../../../styles/vars";

.payment-methods {
	display: flex;
	width: 100%;
	gap: 10px;

	&__container {
		width: 150px;
		height: 110px;
		border-radius: 12px;
		border: 1px solid $blue4;
		padding: 0 10px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			background-color: $blue3;
		}

		img {
			width: 80%;
			height: 80%;
			object-fit: contain;
		}
	}
}
