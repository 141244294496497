@import '../../../styles/vars';

.registration-table {
  .tbody {
    .cell {
      &:last-child { color: $green4; }
      &.passive { color: $red2; }
    }
  }
}
