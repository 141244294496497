@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.section-with-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 23px;
	background-color: $blueGray;
	height: 32px;
	font-weight: bold;
}
