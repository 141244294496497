.register-form {
  width: 480px;
}

.disclaimer {
  font-size: 10px;
  line-height: 12px;
  color: white;
  text-transform: uppercase;
}
