@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.withdraw-page {
  .alt-methods-tab {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 23px;
      background-color: $blueGray;
      height: 32px;
      font-weight: bold;
    }

    &__wrapper {
      display: flex;
      margin: 32px;
      padding: 16px 23px;
      background-color: #f2f3ff;
      border-radius: 5px;
      max-width: 1217px;
      column-gap: 16px;
    }
  }
}
