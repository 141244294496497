@import '../../../styles/vars';
@import '../../../styles/mixins';

.header__time-zone {
  position: relative;
  color: #fff;
  font-size: 14px;

  .dropdown__icon-clock {
    display: block;
    width: 30px;
    height:  30px;
    margin-left: 20px;
    cursor: pointer;
    opacity: 0.6;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    @include transitionEase;

    @include mediaMin(769px) {
      display: none;
    }

    &:hover {
      opacity: 1;
      @include transitionEase;
    }
  }

  .drop-up {
    &:after {
      transform: scale(1, -1);
    }
  }

  .tz-select__default {
    width: 154px;
    height: 42px;
    padding-left: 16px;
    padding-right: 28px;
    display: none;
    border: 1px solid $darkBlue4;
    border-radius: 21px;
    cursor: pointer;
    letter-spacing: none;
    user-select: none;
    @include transitionEase;

    @include mediaMin(769px) {
      @include flexCenterAlign;
    }

    &:after {
      position: absolute;
      right: 4px;
      content: '';
      display: block;
      width: 36px;
      height: 26px;
      background-image: url('../../../assets/svg/drop.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }

    span {
      line-height: 1;
    }
  }

  .dropdown--base {
    position: absolute;
    max-height: 300px;
    overflow-y: auto;
    width: calc(100% - 32px);
    margin-left: 16px;
    top: 42px;
    padding-left: 0;
    z-index: 5;
    list-style: none;
    background: $darkBlue;

    @include mediaMax(840px) {
      width: 120px;
      margin-left: 0;
    }

    .dropdown--item-style {
      color: #fff;
      padding: 10px 8px;
      border-bottom: 1px solid $darkBlue;
      cursor: pointer;
      opacity: 0.5;
      user-select: none;
      @include transitionEase;

      &:hover {
        background: $darkBlue3;
        opacity: 1;
        @include transitionEase;
      }
    }
  }
}

.gray2-color {
  color: $lightGray;
}
