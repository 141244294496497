@import '../../../styles/vars';
@import '../../../styles/mixins';

.deposit-block {
  display: flex;
  height: 100%;
  color: white;

  &__right {
    display: flex;
    padding-left: 10px;

    div {
      @include flexColumn;
      @include flexRowCenter;

      span {
        font-size: 11px;
      }
    }

    div + div {
      margin-left: 8px;
    }
  } 
}
