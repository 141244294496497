.input-container {
	margin: 10px 0;

	&__title {
		font-size: 14px;
		line-height: 2rem;
		color: #898ca6;
	}

	&__wrapper {
		min-height: 40px;
		display: flex;
		align-items: center;
	}

	&__mask {
		font-size: 16px;
		display: flex;
		width: 100px;
		border: 1px solid #e5e5ed;
		border-radius: 5px;
		align-items: center;
		height: 40px;

		img {
			width: 13px;
			height: 13px;
			object-fit: contain;
			margin: 0 5px;
		}

		input {
			border: 0;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			padding: 0 6px;
		}
	}
}
