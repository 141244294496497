@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.form-button {
  width: 100%;
  height: 40px;
  background-color: $darkBlue6;
  border: solid 1px $white;
  border-radius: 30px;
  color: $white;
  text-align: center;
  font-size: 16px;
  outline: none;
  margin: 10px 0 10px 0;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.1s linear;
  padding: 0 10px;

  &:hover:not(:disabled) {
    background-color: $darkBlue5;
  }

  &:active {
    background-color: $blue;
    border-color: $blue;
  }

  &--outlined {
    background-color: $darkBlue5;
    &:hover:not(:disabled) {
      background-color: $darkBlue6;
    }

    &:active {
      background-color: $blue;
      border-color: $blue;
    }
  }

  &--inner {
    font-size: 14px;
    text-transform: none;
    background-color: $blue2;
    border-radius: 5px;
    &:hover:not(:disabled) {
      background-color: $lightBlue2;
    }

    &:active {
      background-color: $blue;
      border-color: $blue;
    }
  }

  &--inner-outlined {
    font-size: 14px;
    text-transform: none;
    color: $blue2;
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $blue2;
    &:hover:not(:disabled) {
      background-color: $lightBlue2;
      color: $white;
    }
    &:active {
      background-color: $blue;
      border-color: $blue;
    }
  }
  &:disabled {
    background-color: $blueGray4;
    cursor: not-allowed;
  }
}
