.table-withdrawals {
	padding: 0 23px;

	&__container {
		margin-top: 16px;
		border-collapse: collapse;
	}

	&__head-cell {
		text-align: center;
		padding: 8px 32px;
		border: none;
	}

	&__cell {
		text-align: center;
	}

	&__button {
		max-width: 160px;
	}
}
