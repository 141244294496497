@import '../../../styles/vars';

.email-confirmation-success {
  &__title {
    margin-bottom: 16px !important;
  }

  &__link {
    font-size: 18px;
    color: $blue8;
  }
}
