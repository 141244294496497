@import "../../../styles/mixins";
@import "../../../styles/vars";

.referral-link-block {
  @include flexCenterAlign;
  justify-content: flex-start;

  &__input {
    cursor: pointer;
    margin-right: 8px;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid $gray3;
  }

  &__clicks {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .icon {
      width: 10px;
      &.down { transform: rotate(180deg); }
    }
  }
}
