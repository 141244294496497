@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.withdraw-page {
  padding: 0 32px;
  &__header {
    box-sizing: border-box;
    height: 80px;
    width: 100%;
    @include flexCenterAlign;
    justify-content: space-between;

    &__title {
      span {
        font-size: 32px;
        &.small {
          font-size: 16px;
          color: $blueGray4;
          margin-left: 24px;
        }
      }
    }
  }
  &__main {
    border: 1px solid $blueGray;
    border-radius: 5px;
  }
  .menu {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: $black;
    line-height: 32px;
    background: $blueGray;
    margin-bottom: 24px;

    &__list {
      display: flex;
      align-items: center;
      margin: 0 0 8px 0;
      padding: 0;
      list-style: none;
      padding-right: 23px;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 23px;
      line-height: 48px;

      white-space: nowrap;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      transition: 0.2s;

      &.active,
      &:hover {
        background: $white;
      }
    }
    .spacer {
      flex: 1;
    }

    &__right-data {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      padding: 0 16px;
    }

    &__right-data + .menu__right-data {
      border-left: 1px solid $blueGray7;
      line-height: 1rem;
    }

    &__right-value {
      font-weight: bold;
    }
  }
}
