@import "../../../../../styles/vars.scss";
@import "../../../../../styles/mixins.scss";

.withdrawal-money {
	&__right-block {
		display: flex;
		align-items: center;
		margin: 0 23px;
		flex: 1;
		.form-button {
			min-width: 120px;
			width: 200px;
		}
	}

	&__fee-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 367px;
		height: 40px;
		background: rgba(230, 233, 255, 0.5);
		border-radius: 5px;
		margin-right: 24px;
		min-width: 320px;
	}

	&__fee {
		margin: 0 24px;
		font-weight: bold;
		font-size: 16px;
	}

	&__net-amount {
		margin: 0 24px;
		color: $blueGray4;
	}

	&__net-amount-value {
		color: $blue2;
	}

	&__error {
		padding-left: 10px;
		color: $red;
	}
}
