@import '../../styles/vars';
@import '../../styles/mixins';

.pagination {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 20px;
  @include flexRowCenter;
  &.disableGutterBottom { margin-bottom: 0; }

  .pagination__item {
    @include flexCenter;
    width: 22px;
    height: 22px;
    margin: 4px;
    border-radius: 5px;
    border: 1px solid $blueGray4;
    color: $blueGray4;
    font-size: 12px;
    cursor: pointer;

    &_active {
      border-color: $blue2;
      color: $blue2;
    }

    &:hover {
      border-color: $blue2;
      color: $blue2;
    }
  }
}
