.confirm-modal-actions {
	padding: 0 24px;
	width: 100%;
	display: flex;
	justify-content: space-between;

	&__add {
		width: 146px;
	}

	&__cancel {
		background-color: #898ca6;
		width: 146px;
	}
}
