.collect-money {
	display: flex;
	align-items: flex-end;
	gap: 23px;
	margin-left: 23px;

	&__select {
		box-sizing: border-box;
		padding: 10px 0;
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;

		&__text {
			height: 40px;
			display: flex;
			align-items: center;
		}
	}

	.error {
		color: #ff0000;
	}

	&__input {
		height: 30px;
		width: 120px;
	}

	& > div {
		max-width: 120px;
		width: 100%;
	}

	& > button {
		max-width: 120px;
		width: 100%;
	}
}
