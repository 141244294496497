.confirm-modal-header {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	font-weight: bold;
	font-size: 24px;
	padding: 0 24px 16px;
	color: #20202e;
	border-bottom: 1px solid #869099;
}
