@import '../../styles/mixins';

.withdrawal-history-page {
  padding: 18px;

  &__title { font-size: 28px; }

  .table-wrapper {
    width: 100%;
    @include mediaMin(992px) {
      width: 70%;
    }
  }
}
