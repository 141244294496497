$white: #ffffff;

$darkBlue: #050711;
$darkBlue2: #050b4e;
$darkBlue3: #1a1d29;
$darkBlue4: #171e52;
$darkBlue5: #060813;
$darkBlue6: #132a9b;
$darkBlue7: #060a13;

$blue: #0062cc;
$blue2: #6464f7;
$blue3: #cdd2ff;
$blue4: #e6e9ff;
$blue5: #5a5ae1;
$blue6: #abb4ff;
$blue7: #4f4fb5;
$blue8: #7c87e6;

$lightBlue: #050b4e;
$lightBlue2: #0010ce;

$gray: #808185;
$gray2: #5d5d5d;
$gray3: #c6c6cf;
$gray4: #d4d4da;
$lightGray: #869099;
$lightGray2: #dee2e6;
$lightGray3: #adadad;

$red: #ff0000;
$red2: #ff5c46;

$green: #abf4d7;
$green2: #2abe82;
$green3: #9adcc1;
$green4: #209a68;
$green5: #7ec3a7;
$green6: #71af96;

$orange: #ffd0bf;
$orange2: #ffe7df;
$orange3: #ffbda6;
$orange4: #ffa382;

$blueGray: #e5e5ed;
$blueGray2: #f7f7ff;
$blueGray3: #f7f7fa;
$blueGray4: #898ca6;
$blueGray5: #d0d3e5;
$blueGray6: #ccccd4;
$blueGray7: #9c9cb4;
$blueGray8: #efeff7;

$black: #20202e;

$yellow: #ffce4f;
$yellow2: #ffebb8;

$dark: #1a1a23;

$error: #da3019;
