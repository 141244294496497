@import "../../../styles/vars";
@import "../../../styles/mixins";

.modal-with-children {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #00000088;

  &__main {
    position: absolute;
    background: $white;
    width: fit-content;
    padding: 24px 0;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 28px;
    right: 22px;
    z-index: 1000;
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: $blue2;
    @include notOutline;

    &:hover { opacity: 0.8; }

    .icon {
      stroke: currentColor;
      width: 1em;
      height: 1em;
    }
  }
}
