@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.added-on-block {
  @include flexColumn;
  align-items: flex-end;

  & > span:last-child {
    display: flex;
    align-items: center;
    padding-top: 4px;
    font-size: 12px;
    color: $blue2;

    & > .icon {
      display: inline-block;
      fill: currentColor;
      width: 1em;
      height: 1em;
      margin-right: 4px;
    }
  }
}
