@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.section-with-body {
	display: flex;
	margin: 32px;
	padding: 16px 23px;
	background-color: #f2f3ff;
	border-radius: 5px;
	max-width: 1217px;
	column-gap: 16px;
}
