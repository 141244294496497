@font-face {
  font-family: 'Bahnschrift Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Bahnschrift Regular'), url('../assets/fonts/BAHNSCHRIFT 1.woff') format('woff');
}

@font-face {
  font-family: 'Bahnschrift Light';
  font-style: normal;
  font-weight: lighter;
  src: local('Bahnschrift Light'), url('../assets/fonts/BAHNSCHRIFT_LIGHT.woff') format('woff');
}
