@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.attention-modal {
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 15px;

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 24px;
    padding: 0 24px 16px;
    color: $black;
    border-bottom: 1px solid $lightGray;
  }

  &__content {
    padding: 10px 24px;
    width: 480px;
  }

  &__buttons {
    padding: 0 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
  }

  &__agree {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__cancel {
    background-color:  #898ca6;
    width: 146px;
  }

  &__add {
    width: 146px;
  }
}
