@import '../../styles/vars';

.operations-table {
  &.gutterTop { margin-top: 16px; }
  & .tbody .cell:nth-child(2):not(.withdrawal) {
    color: $green2;
  }
  & .cell.withdrawal {
    color: $red2;
  }
}
