@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";


.payment-methods-select {
	width: 100%;
	//max-height: 110px;
	height: 100%;
	display: flex;
	gap: 15px;
	margin: 0 0 24px 24px;
	flex-wrap: wrap;

	&__image-container {
		position: relative;
		width: 180px;
		height: 110px;
		border-radius: 12px;
		border: 1px solid $blue4;
		padding: 0 10px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 80%;
			height: 80%;
			object-fit: contain;
		}
	}

	&__checkbox {
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
}
