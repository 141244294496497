@import "../../../styles/vars";
@import "../../../styles/mixins";

.with_preloader {
  height: 100%;
  width: 100%;
  position: relative;
  .with_preloader__img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
