@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.home-page {
  @include fullSize;
  padding: 18px;

  &__title { font-size: 28px; }

  &__main {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding-bottom: 18px;

    @media (max-width: 1279px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__column {
    @include flexColumn;
    gap: 16px;

    &:first-child { width: 45%; }
    &:last-child { width: 55%; }

    @media (max-width: 1279px) {
      margin-right: 0;
      width: 100% !important;
    }
  }

  &__card {
    position: relative;

    & .add-withdrawal-link {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 70px;
      text-align: center;
      text-decoration: underline;
      color: $blue;
    }
  }
}

.read-more {
  margin-top: 12px;
  text-align: right;

  &__link {
    padding-right: 12px;
    font-size: 12px;
    color: $gray;
    text-decoration: underline;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover { color: $gray2; }
  }
}
