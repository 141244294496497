@import '../../../styles/vars';

.phone-input {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 4px;

  .react-tel-input {
    position: relative;
    width: 100%;
    font-family: 'Bahnschrift Regular';
    font-size: 14px;
    font-weight: 700;
    color: $black;
    border-color: $blueGray;

    .flag-dropdown {
      width: 72px;
      border-color: $blueGray;
      background: $blueGray3;
      &.open {
        .selected-flag {
          background: $blueGray3;
        }
      }
      
      .selected-flag {
        width: 100%;
        &:hover { background: $blueGray3; }

        .arrow {
          left: 26px;
          width: 24px;
          height: 24px;
          margin-top: -12px;
          background-image: url('../../../assets/svg/chevron_top--color.svg');
          background-repeat: no-repeat;
          background-position: center center;
          border: none;
          transform: rotate(-180deg);
          transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

          &.up {
            transform: rotate(0deg);
          }
        }
      }
    }

    .form-control {
      width: 100%;
      height: 42px;
      padding-left: calc(72px + 12px);
      padding-right: 42px;
      border-color: $blueGray;

      &::placeholder {
        opacity: 1;
        color: $black;
      }
    }

    .country-list {
      box-shadow: none;
      border: 1px solid $blueGray;
      border-radius: 0 0 5px 5px;
      margin: 0 0 0 -1px;

      .country:hover { background: $blueGray3; }
    }
  }

  &__end-button {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    font-size: 14px;
    color: $blueGray7;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    .icon {
      width: 1em;
      height: 1em;
      stroke: currentColor;
    }

    &:hover { color: $blue2; }
  }
}
