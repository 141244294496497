@import '../../../../styles/vars';

.lang-select-wrapper {
  $borderRadius: 5px;

  cursor: pointer;
  height: 34px;
  margin-right: -8px;

  &.active .select {
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    border-color: $blueGray;
  }

  .flag-icon {
    user-select: none;
    margin-right: 4px;
  }
  .lang {
    width: 18px;
    padding-top: 3px;
    font-weight: 700;
    line-height: 14px;
    text-transform: uppercase;
  }

  .select {
    display: flex;
    align-items: center;
    padding: 8px;
    border: 1px solid transparent;
    border-bottom: none;
  }

  .options {
    list-style: none;
    width: 100%;
    padding-bottom: 4px;
    background-color: #fff;
    border: 1px solid $blueGray;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;

    .option {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      border-bottom: 1px solid $blueGray;
      &:hover, &.selected { background-color: $blueGray; }
      &:last-child { border: none; }
    }
  }
}
