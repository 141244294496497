@import '../../../../styles/vars';

.registration-success {
  &__title {
    margin-bottom: 16px !important;
  }

  &__text {
    margin: 0 !important;
  }
}
