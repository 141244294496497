@import '../../../../styles/vars';

.terms-of-service-modal {
  padding-left: 32px;
  padding-right: 22px;
  width: 480px;

  &__title {
    font-size: 28px;
    line-height: 28px;
    color: #20202e;
  }

  &__text-wrapper {
    overflow-y: auto;
    max-height: 420px;
    margin: 24px 0;
    padding: 8px 2px;
    border-top: 1px solid $lightGray2;
    border-bottom: 1px solid $lightGray2;

    &-md {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 0.7em;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.01em;
      }

      h1:not(:first-child),
      h2:not(:first-child),
      h3:not(:first-child),
      h4:not(:first-child),
      h5:not(:first-child),
      h6:not(:first-child) {
        margin-top: 2em;
      }

      p {
        margin-bottom: 0.5em;
        font-size: 15px;
        opacity: 0.8;
      }
      p:last-child {
        margin-bottom: 0;
      }

      ul,
      ol {
        padding-left: 16px;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
      ul { list-style: disc !important; }
      ol { list-style: decimal !important; }
    }
  }

  &__agreement {
    font-size: 14px;

    label, input[type="checkbox"] { cursor: pointer; }
    input[type="checkbox"] { margin-right: 8px; }
  }

  &__footer {
    padding-top: 24px;

    .button { margin-right: 12px; }
  }
}
