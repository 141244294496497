@import './fonts';
@import './vars';
@import './mixins';

*,
*:before,
*:after {
  font-family: 'Bahnschrift Regular', 'Roboto', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection { background-color: $blue3; }

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
}

button {
  background: transparent;
  border: none;
}

input:focus, button:active {
  outline: none !important;
}

a {
  color: #fff;
  text-decoration: none;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $darkBlue2;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: $darkBlue4;
}


h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.8rem;
}

.table {
  width: 100%;
  font-family: 'Circe';

  .thead, .tbody {
    text-align: left;

    .cell:not(:first-child) { text-align: center; }
  }

  .thead {
    background-color: $blueGray;
    color: $black;
    line-height: 14px;

    .cell {
      padding: 9px 32px;
      font-size: 14px;
    }
  }

  .tbody {
    color: #000;

    .row {
      height: 15px;
      &:nth-child(even) {
        background-color: $blueGray3;
      }
    }

    .cell {
      padding: 16px 32px;
      font-size: 12px;
      &:not(:last-child) { border-right: 1px solid $blueGray; }
    }
  }
}

.card {
  border: 1px solid $blueGray;
  border-radius: 5px;
  padding: 16px;
  font-size: 14px;
  background-color: #fff;

  @media (max-width: 767px) {
    width: max-content;
    min-width: 100%;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 16px;
  }

  &__content { @include flexBetween; }

  &__column {
    @include flexColumn;

    &-description {
      display: inline-block;
      margin-bottom: 6px;
      font-size: 12px;
      color: $gray;
    }

    &.left {  align-items: flex-start; }
    &.right {
      padding-left: 16px;
      flex-shrink: 0;
      align-items: flex-end;
      &.center { justify-content: center; }
    }

    &.scrollX { overflow-x: auto; }
  }
}

button {
  cursor: pointer;
}

.button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 146px;
  padding: 13px 19px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1em;
  color: white;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &--primary {
    background-color: $blue2;
    &:hover { background-color: $blue5; }
  }

  &--secondary {
    background-color: $blueGray4;
    &:hover { background-color: $blueGray7; }
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
}

.link {
  font-size: 14px;
  font-weight: 700;
  line-height: 1em;
  color: white;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover { color: $gray3; }
}
