@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.icon-btn {
  @include flexCenter;
  width: 32px;
  height: 32px;
  background-color: $blue4;
  border-radius: 5px;
  border: none;
  outline: none !important;
  cursor: pointer;

  &:hover {
    background-color: $blue3;
  }

  &--small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
  }

  &--dark {
    background-color: $blue3;
    &:hover {
      background-color: $blue6;
    }
  }

  &__img {
    height: 16px;
    &--rotation {
      animation: rotation 1.5s infinite linear;
    }
  }
  &:disabled {
    opacity: 0.5;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
