
@import '../../styles/vars';
@import '../../styles/mixins';

header {
  width: 100%;
  height: 80px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #171e52;
  z-index: 100;
  background-image: url('../../assets/img/header_bgd.jpg');
  box-sizing: border-box;

  .header__left {
    @include flexCenterAlign;

    .header__logo-desktop {
      display: none;

      @include mediaMin(992px) {
        display: block;
      }
    }

    .header__logo-mobile {
      display: block;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      @include mediaMin(992px) {
        display: none;
      }
    }

    img {
      height: 50px;

      @include mediaMin(992px) {
        padding-top: 12px;
        height: 100%;
      }
    } 
  }

  .header__right {
    @include flexCenterAlign;
  }

  .empty-block {
    display: none;
    width: 54px;

    @include mediaMax(1092px) {
      display: block;
    }
  }
}
