@import '../../styles/mixins';
@import '../../styles/vars';

.auth-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 40px);
  overflow: hidden;
  background: #50a7bb url('../../assets/img/auth_bg.jpg') no-repeat center center fixed;
  background-size: cover;
  @include mediaMin(992px) {
    justify-content: flex-end;
  }

  &__form-wrapper {
    @include mediaMin(992px) {
      margin-right: 240px;
    }

    .form {
      margin-bottom: 12px;
      padding: 32px;

      &__title {
        margin-bottom: 36px;
        font-size: 36px;
        line-height: 36px;
        color: $black;
      }

      &__group {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 8px;

        .input {
          width: 100%;
          margin-bottom: 4px;
          padding: 12px 16px;
          border-radius: 5px;
          border: 1px solid $blueGray;
        }

        .error {
          font-size: 12px;
          line-height: 12px;
          color: $error;
        }
      }

      &__label {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 14px;
        color: $blueGray4;
      }

      &__text {
        margin: 0;
        padding-bottom: 16px;
        font-size: 16px;
        line-height: 1.5em;
        color: $blueGray4;

        a {
          color: $blue8;
        }
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        height: 40px;
      }

      &__actions {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        height: 40px;
      }

      .error {
        font-size: 12px;
        line-height: 18px;
        color: $error;

        a {
          color: $error;
          text-decoration: underline;
        }
      }
    }
  }
}
