@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexEnd {
  display: flex;
  justify-content: flex-end;
}

@mixin flexBetween {
  display: flex;
  justify-content: space-between;
}

@mixin flexCenterAlign {
  display: flex;
  align-items: center;
}

@mixin flexRowCenter {
  display: flex;
  justify-content: center;
}

@mixin flexColumn {
  display: flex;
  flex-direction: column;
}

@mixin transitionInOut {
  transition: all 0.3s ease-in-out;
}

@mixin transitionEase {
  transition: all 0.5s ease;
}

@mixin mediaMax($width) {
  @media screen and (max-width: $width) {
      @content;
  }
}

@mixin mediaHeightMax($height) {
  @media screen and (max-height: $height) {
      @content;
  }
}

@mixin mediaMin($width) {
  @media screen and (min-width: $width) {
      @content;
  }
}

@mixin contentHeight {
  height: calc(100vh - 120px);
}

@mixin absolute {
  position: absolute;
  top: 0;
  left: 0;
}

@mixin absoluteRight {
  position: absolute;
  top: 0;
  right: 0;
}

@mixin absolute100 {
  @include absolute;
  width: 100%;
  height: 100%;
}

@mixin fullSize {
  width: 100%;
  height: 100%;
}

@mixin boxShadow {
  box-shadow: 0 0 2px rgba(0,0,0,.3);
}

@mixin notOutline {
  outline: none !important;
  border: none !important;
}

@mixin mobileMenuBtn {
  position: fixed;
  top: 22px; 
  width: 35px;
  height: 35px;
  padding: 6px;
  border-radius: 50%;
  border: 1.5px solid #fff;
  cursor: pointer;
  opacity: 0.5;
  z-index: 300;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @include flexCenter; 
  @include transitionInOut;
}

@mixin grayScroll {
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
    cursor: pointer;
  }
  
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: $blueGray;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background-color: $blueGray5;
  }
}
