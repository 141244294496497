@import '../../../styles/vars';
@import '../../../styles/mixins';

.header__auth {
  @include flexCenterAlign;
  margin-right: 15px;

  @include mediaMax(599px) {
    margin-right: 0;
  }

  .header__user-image-wrapper {
    opacity: 0.5;
    cursor: pointer;
    @include transitionEase;

    &:hover {
      @include transitionEase;
      opacity: 1;
    }

    @include mediaMin(600px) {
      margin-right: 10px;
    }
  }

  .header__auth-controls {
    position: relative;
    @include flexCenterAlign;

    @include mediaMax(840px) {
      display: none;
    }

    .slash {
      color: $gray;
      margin: 0 5px;
    }

    a {
      color: $gray;
      font-size: 14px;
      cursor: pointer;
      @include transitionEase;
      @include flexCenterAlign;

      &:hover {
        color: #fff;
        @include transitionEase;
      }

      .ic_login {
        @include flexCenterAlign;
        opacity: 0.6;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }

        img {
          box-sizing: border-box;
          padding: 10px;
          width: 69px;
          opacity: 0.6;
        }
      }
    }

    .intl__dropdown {
      position: absolute;
      top: 75px;
      left: 16px;
      min-width: 180px;
      padding-left: 0;
      list-style: none;
      background: $darkBlue7;
      z-index: 5;
      font-size: 13px;
      color: white;

      .dropdown--item-style {
        cursor: pointer;
        padding: 16px 20px;
        user-select: none;
        @include transitionEase;

        &:hover { background-color: $darkBlue3; }
      }

      .user-info {
        list-style: none;
        padding: 12px 0;
        border-bottom: 1px solid $lightBlue;

        &__item {
          padding: 4px 20px;
          &:last-child { margin-bottom: 0; }

          .title {
            padding-bottom: 4px;
            color: $gray;
          }
          .info {
            line-height: 14px;
          }

          &.user-info__link {
            position: relative;
            display: block;
            width: 100%;
            color: white;

            .icon {
              position: absolute;
              top: 16px;
              right: 20px;
              transform: rotate(180deg);
              stroke: white;
              transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }

            &:hover {
              color: $blue2;
              background-color: $darkBlue3;
              .icon { stroke: $blue2; }
            }
          }
        }
      }

      .btn_white {
        width: 104px;
        height: 28px;
        margin: 10px 8px 10px;
        color: black;
        background: linear-gradient(to bottom, #e5e5e5, #a2a2a2);
        font-size: 12px;
        font-weight: bold;
        @include notOutline;
        cursor: pointer;

        @media (max-width: 1599px) {
          width: 50px;
          height: 24px;
          font-size: 10px;
        }

        &:hover {
          background: white;
        }

        &_disabled {
          color: #545C74;
          background-color: #3C4253;
        }
      }
    }
  }

  .header__auth-mobile {
    position: relative;
    display: none;

    @include mediaMax(840px) {
      display: block;
    }

    .user-image-avatar {
      display: block;
      width: 48px;
      height: 48px;
      margin-left: 10px;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }

    &:hover {
      .header__auth-mobile__menu {
        @include flexColumn;
      }
    }

    &__menu {
      position: absolute;
      top: 46px;
      right: 0;
      width: 140px;
      padding: 10px;
      display: none;
      background-color: $darkBlue;

      span {
        margin: 8px;
        color: $white;
        opacity: 0.6;
      }
    }
  }
}
