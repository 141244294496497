@import '../../styles/vars';
@import '../../styles/mixins';

.footer {
  display: flex;
  width: 100%;
  height: 40px;
  border-top: 1px solid $lightGray2;
  height: 40px;
  padding-left: 1rem;
  background-image: url('../../assets/img/footer_bgd.jpg');
  overflow: hidden;

  .footer__copyright {
    @include flexCenterAlign;
    height: 100%;
    margin: 0;
    color: $lightGray;
    font-weight: normal;
    font-size: 13px;
  }

  .footer__icons {
    margin-left: 20px;
    @include flexCenterAlign;

    img {
      height: 22px;
      margin: 0 8px;
    }

    .big-img {
      height: 30px;
    }
  }

  .footer__content {
    @include flexCenterAlign;
    margin-left: 4px;
    padding: 6px 10px;
    flex: 1;
    color: $lightGray;
    font-size: 9px;

    @media (max-width: 1599px) {
      font-size: 8px;
      padding: 3px 8px;
    }

    p {
      line-height: 1;
      margin-bottom: 0;
    }
  }
}
