.td-default {
	padding: 4px 8px;
	font-size: 12px;
	box-sizing: border-box;
	border-right: 1px solid #e5e5ed;

	&:last-child {
		border: none;
	}
}
