@import "../../../../styles/vars.scss";
@import "../../../../styles/mixins.scss";

.payment-information {
	ol {
		padding-left: 23px;
		list-style: none;
		counter-reset: my-awesome-counter;
	}

	ol li {
		counter-increment: my-awesome-counter;
		margin: 16px 0;
	}

	ol li::before {
		content: counter(my-awesome-counter);
		color: $blue2;
		display: inline-block;
		margin-right: 10px;
		font-weight: bold;
		font-size: 16px;
		border: 1px solid $blueGray;
		text-align: center;
		width: 30px;
		height: 30px;
		padding-top: 5px;
		border-radius: 50%;
	}
}
