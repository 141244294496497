.main {
  position: relative;
  height: calc(100vh - 120px);
  overflow: hidden;
  display: flex;

  .routing-component {
    flex: 1;
    overflow-y: auto;
  }
}
