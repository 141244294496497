@import '../../../styles/mixins';
@import '../../../styles/vars';

.modal-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 460px;
  height: 350px;
  padding: 32px 24px;
  color: $black;
  text-align: center;

  &__title {
    font-weight: 400;
    padding-bottom: 24px;
    font-size: 24px;
    line-height: 24px;
  }

  &__icon {
    padding-bottom: 36px;
    width: 48px;
    height: 48px;
    box-sizing: content-box;
  }

  &__text {
    width: 100%;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 24px;
  }

  &__ref {
    width: 100%;
    padding: 8px 0;
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 18px;
    color: $blueGray4;
  }

  &__confirm-button {
    cursor: pointer;
    padding: 13px 62px;
    background-color: $blue2;
    border-radius: 5px;
    font-weight: 700;
    color: white;
  }
}
