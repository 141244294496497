@import '../../../../styles/vars';

.password-recover-success {
  &__title {
    margin-bottom: 16px !important;
  }

  &__text {
    margin: 0 !important;
  }

  &__link {
    font-size: 18px;
    color: $blue8;
  }
}
