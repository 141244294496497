.card-container {
	width: 100%;
	padding-left: 24px;
	display: flex;
	gap: 20px;
	align-items: flex-end;

	&__header {
		display: flex;
		flex-direction: column;

		span {
			font-size: 14px;
			line-height: 2rem;
			color: #898ca6;
		}
	}

	&__error {
		box-sizing: border-box;
		padding: 10px 0;
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;

		&__text {
			height: 40px;
			display: flex;
			align-items: center;
			color: #ff0000;
		}
	}

	&__stripe {
		width: 250px;
	}

	&__bitgo {
		width: 400px;
	}
}
