@import "../../../styles/vars";
@import "../../../styles/mixins";

.with_error_message {
  min-height: 120px;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  position: relative;
  .with_error_message__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
