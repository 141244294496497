@import '../../../styles/vars';

.filter {
  display: flex;
  align-items: flex-end;
  margin-bottom: 26px;
  gap: 18px;

  .selector {
    position: relative;
    display: flex;
    padding-top: 26px;

    .title {
      position: absolute;
      top: 0;
      left: 0;
      color: $black;
    }

    .wrapper {
      width: 240px;
    }
  }

  .button { margin-bottom: -4px; }
}
