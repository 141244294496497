@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.checkbox {
	position: relative;
	width: 15px;
	height: 15px;

	&__fake {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border: 1px solid $blue2;
		border-radius: 6px;
	}

	.checked {
		background-color: $blue5;

		&:before {
			content: '✓';
			position: absolute;
			top:50%;
			left:50%;
			transform:translate(-50%, -50%);
			font-size: 10px;
			color: white;
		}
	}

	input {
		position: relative;
		opacity: 0;
		width: 100%;
		z-index: 1000;
		height: 100%;
	}
}
