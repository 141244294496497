@import '../../../styles/vars';
@import '../../../styles/mixins';

.header__intl {
  height: 100%;
  position: relative;
  margin-left: 26px;
  color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .intl__default {
    width: 43px;
    height: 100%;
    cursor: pointer;
    @include flexColumn;
    justify-content: center;
  }

  .intl__dropdown {
    position: absolute;
    top: 80px;
    right: 4px;
    padding-left: 0;
    list-style: none;
    background: #060a13;
    z-index: 5;

    .intl__item {
      color: #fff;
      padding: 4px 8px;
      border-bottom: 1px solid $darkBlue;
      cursor: pointer;
      @include flexCenterAlign;
      @include transitionEase;

      &:hover {
        background: $darkBlue3;
      }

      .icon--size {
        width: 36px;
        height: 36px;
      }

      & span {
        margin-left: 10px;
        display: block;
      }
    }
  }
}
