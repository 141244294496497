@import '../../../styles/vars';
@import '../../../styles/mixins';

.date-selector {
  position: relative;
  width: 100%;
  line-height: 32px;
  font-size: 12px;
  cursor: pointer;
  user-select: none;

  &.disabled { pointer-events: none; }

  .date-selector__main {
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    padding-left: 16px;
    border-radius: 5px;
    font-weight: bold;
    background-color: $blue4;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0px;
      width: 32px;
      height: 32px;
      background: url('/assets/svg/calendar.svg') center no-repeat $blue3;
      border-radius: 0 5px 5px 0;
    }
  }

  &.disabled .date-selector__main {
    background-color: $blueGray5;
    color: $black;
    cursor: auto;
  }

  &.disabled .date-selector__main::after {
    opacity: 0.5;
    cursor: auto;
  }

  .calendar {
    position: absolute;
    left: 0;
    top: 40px;
    width: 280px;
    height: 268px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px !important;
    z-index: 10;
    @include boxShadow;

    .react-calendar__month-view__days {
      box-sizing: border-box;
      padding: 0 14px 14px 14px;

      .react-calendar__month-view__days__day--neighboringMonth {
        color: $blueGray7;
      }
    }

    .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
      display: none;
    }

    .react-calendar__navigation__arrow {
      height: 100%;
      width: 35px;
      color: transparent;
      @include notOutline;
      cursor: pointer;
      background: url('/assets/svg/arrow_left.svg') no-repeat center center $blue5;
    }

    .react-calendar__navigation__next-button {
      transform: rotate(180deg);
    }

    .react-calendar__navigation {
      height: 39px;
      background-color: $blue2;

      .react-calendar__navigation__label__labelText {
        font-weight: bold;
        color: white;
      }
    }

    .react-calendar__tile {
      flex-basis: 36px !important;
      width: 36px !important;
      height: 36px !important;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        background-color: $blue3;
        color: white;
      }

      &--active {
        background-color: $blue2;
        color: white;
        @include notOutline;
      }
    }

    .react-calendar__month-view__weekdays {
      padding-left: 16px;

      &__weekday {
        font-weight: bold;
        color: $blue2;
        flex-basis: 36px !important;
        text-align: center;
  
        abbr {
          text-decoration: none;
          cursor: auto;
        }
      }
    }
  }
}
